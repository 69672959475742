<template>
  <div class="Exams" v-if="isOpen">
    <v-card>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :server-items-length="params.total"
        :options.sync="pagination"
        :items-per-page="params.size"
        class="elevation-1"
        :no-data-text="'Data not available from this resource'"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 300, 500, -1],
        }"
      >
        <!-- <template v-slot:item.isCurrent="{ item }">
          <v-switch readonly v-model="item.isCurrent"></v-switch>
        </template> -->

        <template v-slot:top>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="12" md="4" class="pa-0">
              <SearchField
                :search-function="searchOptions"
                :prepend-icon="'mdi-magnify'"
                :label="'Search Data'"
                :append-icon="''"
              />
            </v-col>
          </v-card-title>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            color="primary"
            @click="action(item, 'openScores')"
            class="white--text ml-2 mr-2"
          >
            <v-icon>mdi-alert-circle-check</v-icon>
            {{ buttonText }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { filterExams as loadExams } from "../../examinations/services";

export default {
  components: {},
  name: "LoadExaminations",
  props: {
    acceptFunction: { type: Function, required: true },
    isOpen: { type: Boolean, default: false, required: true },
    examParams: { type: Object, required: true },
    buttonText: { type: String, required: false },
  },
  data() {
    return {
      items: [],
      isEditing: false,
      formData: {},
      params: {},
      loading: false,
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
        },
        // {
        //   text: "Class",
        //   align: "start",
        //   sortable: false,
        //   value: "school_class.name",
        //   class: "primary--text",
        // },
        {
          text: "Exam Name",
          align: "start",
          sortable: false,
          value: "name",
          class: "primary--text",
        },
        {
          text: "Subject",
          align: "start",
          sortable: false,
          value: "subject.name",
          class: "primary--text",
        },

        {
          text: "Date",
          align: "start",
          sortable: false,
          value: "date",
          class: "primary--text",
        },
        {
          text: "Type",
          align: "start",
          sortable: false,
          value: "type.name",
          class: "primary--text",
        },

        {
          text: "Assessment Level",
          align: "start",
          sortable: false,
          value: "level.name",
          class: "primary--text",
        },
        // {
        //   text: "Description",
        //   align: "start",
        //   sortable: false,
        //   value: "purpose",
        //   class: "primary--text",
        // },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "primary--text",
        },
      ],
    };
  },

  computed: {
    pagination: {
      get() {
        return { ...this.params, ...this.examParams };
      },

      set(value) {
        // console.log(value)
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;

        this.params.total = this.params.total;

        this.init({ ...this.params, ...this.examParams });
      },
    },
    user() {
      return this.$mixins.currentUser();
    },
  },

  methods: {
    init(params) {
      this.loading = true;
      let payload = {
        type_id: params?.examType?.id,
        school_class_id: params?.schoolClass?.class_id,
        year_id: params?.year?.id,
        school_id: params?.school?.id,
      };
      loadExams(payload)
        .then((response) => {
          this.loading = false;
          this.params.total = response.data.to;
          this.items = response.data
            ? response.data.data
                .map((item) => ({
                  ...item,

                  schoolClass: item.school_class,
                }))

                .map((item, index) => ({
                  ...item,
                  index: ++index,
                }))
            : [];
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    searchOptions(optionName) {
      this.init({
        search: optionName ? optionName : null,
        size: this.params.size,
        total: this.params.total,

        //...this.params,
      });
    },

    action(data, action) {
      let payload = { ...data, ...this.examParams };

      this.acceptFunction(payload, action);
    },
  },

  watch: {
    pagination: {
      handler() {
        this.loading = true;
        this.init({ ...this.params, ...this.examParams });
      },
      update() {
        this.loading = true;
        this.init({ ...this.params, ...this.examParams });
      },
      deep: true,
    },
  },

  mounted() {},
};
</script>

<style scoped></style>
